/**
 * This code was generated by Builder.io
 */
import React from 'react'
import { Button, Input, Pagination, Table } from 'antd'
import { CommonHeader } from '../../layout/components/CommanHeader'
import styled from 'styled-components'

const FilterSection = styled.div`
  margin-bottom: 24px;
`

const ContentWrapper = styled.div`
  padding: 0 12px;

  @media (min-width: 768px) {
    padding: 0 16px;
  }
`

const PendingIncentive: React.FC = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ]

  const data = [
    {
      key: '1',
      name: 'John Doe',
      amount: '$100',
      date: '2024-03-20',
      status: 'Pending',
    },
    // Add more data as needed
  ]

  return (
    <main>
      <CommonHeader
        image='https://s3-alpha-sig.figma.com/img/8f90/687e/6a4e88186aff9c673b73718f331f55c0?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=k5vN4CiZl1EHRdIVetc84OdufzdDnB1b4QFqQGSVLJNIhRboFPVKr3X0Cz9JNRJ4kjTZ4xF9B7QavtoUkMHhJ2WyLDSltva42t6z9AgnPKN-nkSXmJRiUrJn4suyX8fbRj1MpqInwkvozPyCb1T2OB73~beyXyq97WO94tCgpUjvqov5z868PF8ZOhKmzs2ns3Lh5ZQOjwzujIN-4auRX1PVjhNdED~0sjUQDnFYtJ7YXPMEqKo6O3jViaDZIKSHGiIRYnznigtGzs0d-qX7MOaqTBOJqQ86pXG2zOfMkqyFApAYpZ08IXAwhvRtPG6nimQyxjGjwGIBFij3zoELMw__'
        title='Pending Incentives'
        buttons={[
          <Button size='large' style={{ borderColor: 'green', color: 'green' }}>
            Approve
          </Button>,
          <Button size='large' style={{ borderColor: 'red', color: 'red' }}>
            Decline
          </Button>,
        ]}
      />
      <ContentWrapper>
        <FilterSection>
          <Input placeholder='Search' style={{ width: 317, marginTop: 8 }} />
        </FilterSection>

        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          style={{ marginBottom: 16 }}
        />

        <Pagination />
      </ContentWrapper>
    </main>
  )
}

export default PendingIncentive
