import * as React from 'react'
import { Modal } from 'antd'
import { EditIncentiveForm } from './EditIncentiveForm'
import { EditIncentiveHeader } from './EditIncentiveHeader'
import styled from 'styled-components'

interface EditIncentiveModalProps {
  isOpen: boolean
  onClose: () => void
  incentiveId: string
}

export const EditIncentiveModal: React.FC<EditIncentiveModalProps> = ({
  isOpen,
  onClose,
  incentiveId,
}) => {
  return (
    <StyledModal
      title={<EditIncentiveHeader />}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      width={762}
      destroyOnClose
    >
      <EditIncentiveForm incentiveId={incentiveId} onClose={onClose} />
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
    padding: 36px 0;
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-header {
    padding: 0 39px;
    border-bottom: none;
  }
`
