import { useRoutes } from 'react-router-dom'
import { Dashboard } from '../pages/admin/Dashboard'
import CurrentIncentive from '../pages/admin/CurrentIncentive'
import { Layout } from '../layout/Layout'
import PendingIncentive from '../pages/admin/PendingIncentive'
import ConsumerDashboard from '../components/consumerDashboard/ConsumerDashboard'

const FinalRoutes = () => {
  const routes = [
    {
      element: <Dashboard />,
      path: '/',
    },
    {
      element: <CurrentIncentive />,
      path: '/current-incentive',
    },
    {
      element: <PendingIncentive />,
      path: '/pending-incentive',
    },
    {
      element: <ConsumerDashboard />,
      path: '/consumer-dashboard',
    },
  ]

  const finalRoutes = [
    {
      element: <Layout />,
      children: routes,
    },
  ]

  return useRoutes(finalRoutes)
}

export { FinalRoutes }
