/**
 * This code was generated by Builder.io
 */
import React from 'react'
import { Layout } from 'antd'
import { HeaderSection } from './HeaderSection'
import { DashboardContent } from './DashboardContent'
import './styles.css'
import { Pagination } from '../incentives/Pagination'

const { Content } = Layout

const ConsumerDashboard: React.FC = () => {
  return (
    <>
      <Layout className='admin-dashboard'>
        <Layout>
          <Content
            style={{ margin: '0', overflow: 'initial', minHeight: '100vh' }}
          >
            <HeaderSection />
            <DashboardContent />
            <Pagination />
          </Content>
        </Layout>
      </Layout>
    </>
  )
}

export default ConsumerDashboard
