import styled from 'styled-components'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 27px;
  
  
  > *:not(:first-child) {
    padding: 0 40px;
  }
`

const HeaderImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.h1`
  color: #1c304c;
  font-size: 32px;
  font-weight: 800;
  font-family: Mulish, sans-serif;
  margin: 0;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const CommonHeader = ({
  image,
  title,
  buttons,
}: {
  image: string
  title: string
  buttons: React.ReactNode[]
}) => {
  return (
    <Header>
      <HeaderImage src={image} alt='Header background' />
      <HeaderContent>
        <Title>{title}</Title>
        <ButtonsContainer>{buttons}</ButtonsContainer>
      </HeaderContent>
    </Header>
  )
}
