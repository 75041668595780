/**
 * This code was generated by Builder.io
 */
import React from 'react'
import { Layout, Typography, Row, Col, Space } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const { Footer } = Layout
const { Text, Link } = Typography

export const GlobalFooter: React.FC = () => {
  return (
    <Footer style={{ background: '#f9f9f9', padding: '16px 24px' }}>
      <Row justify='space-between' align='middle'>
        <Col>
          <Space direction="vertical" size="small" style={{ color: '#666' }}>
            <Text>© Incentive Wizard</Text>
            <Text>Powered by Best Payment Solutions</Text>
          </Space>
        </Col>
        <Col>
          <Space direction="horizontal" split="|">
            <Link href="/privacy-policy" style={{ color: '#666' }}>Privacy Policy</Link>
            <Link href="/terms-of-service" style={{ color: '#666' }}>Terms of Service</Link>
          </Space>
        </Col>
      </Row>
    </Footer>
  )
}
