import React from "react";
import styled from "styled-components";
import { Pagination as AntPagination } from "antd";

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 38px;
`;

export const Pagination: React.FC = () => {
  return (
    <PaginationContainer>
      <AntPagination
        defaultCurrent={1}
        total={50}
        pageSize={10}
        showSizeChanger={false}
      />
    </PaginationContainer>
  );
};
