/**
 * This code was generated by Builder.io
 */
import React from 'react'
import { Menu, Button, Drawer } from 'antd'
import { MenuOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

interface NavItem {
  key: string
  icon: React.ReactNode
  label: string | React.ReactNode
  className?: string
}

const mainNavItems: NavItem[] = [
  { key: '', icon: <img src='/icons/home.svg' alt='Home' />, label: 'Home' },
  {
    key: 'current-incentive',
    icon: <img src='/icons/current-incentives.svg' alt='Trophy' />,
    label: 'Current Incentives',
  },
  {
    key: 'pending-incentive',
    icon: (
      <img src='/icons/pending-incentives-reward-history.svg' alt='Dollar' />
    ),
    label: 'Pending Incentives',
  },
  {
    key: 'search',
    icon: <img src='/icons/search-incentives.svg' alt='Search' />,
    label: 'Search Incentives',
  },
  {
    key: 'reports',
    icon: <img src='/icons/reports.svg' alt='Chart' />,
    label: 'Reports',
  },
  {
    key: 'communications',
    icon: <img src='/icons/communications-inbox.svg' alt='Message' />,
    label: 'Communications',
  },
  {
    key: 'users',
    icon: <img src='/icons/users-my-accoun.svg' alt='User' />,
    label: 'Users',
  },
]

const bottomNavItems: NavItem[] = [
  {
    key: 'faqs',
    icon: <img src='/icons/faq.svg' alt='Question' />,
    label: 'FAQs',
  },
  {
    key: 'help',
    icon: <img src='/icons/help-center.svg' alt='Support' />,
    label: 'Help Center',
  },
  {
    key: 'signout',
    icon: <img src='/icons/sign-out.svg' alt='Logout' />,
    label: 'Sign Out',
  },
]

const consumerNavItems: NavItem[] = [
  {
    key: 'my-reward',
    icon: null,
    label: (
      <span className='my-reward-label'>
        Manage My Reward Card
        <img
          src='/icons/open-in-new-window.svg'
          alt='External Link'
          className='external-link-icon'
        />
      </span>
    ),
    className: 'my-reward-item',
  },
  { key: '', icon: <img src='/icons/home.svg' alt='Home' />, label: 'Home' },
  {
    key: 'current-incentive',
    icon: <img src='/icons/current-incentives.svg' alt='Trophy' />,
    label: 'Current Incentive',
  },
  {
    key: 'reward-history',
    icon: (
      <img src='/icons/pending-incentives-reward-history.svg' alt='Dollar' />
    ),
    label: 'Reward History',
  },
  {
    key: 'inbox',
    icon: <img src='/icons/message.svg' alt='Message' />,
    label: 'Inbox',
  },
  {
    key: 'my-account',
    icon: <img src='/icons/user.svg' alt='User' />,
    label: 'My Account',
  },
]

const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,0,0,0.1);
  width: 100vw;
`

const MobileLogo = styled.img`
  height: 72px;
  width: auto;
`

const DesktopLogo = styled.img`
  width: 190px;
  padding: 16px;
`

const NavigationWrapper = styled.div`
  width: 100%;
    box-shadow: 0 3px 4px rgba(0,0,0,0.4);
  
  @media (min-width: 768px) {
    width: 250px;
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
  }
`

const StyledMenu = styled(Menu)`
  &.ant-menu {

    &.main-menu {
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
     
    }

    .ant-menu-item {
      &:hover {
        background-color: #F0F4FA !important;
      }
      
      &.ant-menu-item-selected {
        background-color: #F0F4FA !important;
        
        .ant-menu-item-icon,
        .ant-menu-title-content {
          color: #0478c2 !important;
        }
      }

      &.my-reward-item {
        background-color: #0478c2 !important;
        border-radius: 4px;
        
        .my-reward-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .external-link-icon {
          margin-left: 8px;
          font-size: 14px;
        }

        .ant-menu-item-icon,
        .ant-menu-title-content {
          color: white !important;
        }

        &:hover {
          background-color: #0367a6 !important;
        }
      }
    }
  }
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 215px);
  overflow: hidden;

  @media (max-width: 768px) {
    height: 100%;
  }
  
  .main-menu {
    flex: 1 1 auto;
    overflow-y: auto;
  }
  
  .bottom-menu {
    flex: 0 0 auto;
    border-top: 1px solid #f0f0f0;
    padding-bottom: 24px;
  }
`

export const Navigation: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const navigate = useNavigate()
  const [drawerVisible, setDrawerVisible] = React.useState(false)

  const userType =
    process.env.REACT_APP_SITE === 'consumer' ? 'consumer' : 'admin'

  const handleMenuClick = ({ key }: { key: string }) => {
    setDrawerVisible(false)
    switch (key) {
      case 'signout':
        // Handle sign out logic here
        break
      default:
        navigate(`/${key}`)
        break
    }
  }

  const MenuComponent = (
    <MenuContainer>
      <StyledMenu
        className='main-menu'
        mode='inline'
        defaultSelectedKeys={['home']}
        items={userType === 'consumer' ? consumerNavItems : mainNavItems}
        onClick={handleMenuClick}
      />
      <StyledMenu
        className='bottom-menu'
        mode='inline'
        items={bottomNavItems}
        onClick={handleMenuClick}
      />
    </MenuContainer>
  )

  if (isMobile) {
    return (
      <>
        <MobileHeader>
          <MobileLogo
            src='https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/88af23246c5b80b6feb8a2888b2c7f6240a76e6d37e64dc070a3ae0f1e4778b3?apiKey=94b01dca26f547a09eaf89466406fc88&'
            alt='Company Logo'
            onClick={() => navigate('/')}
            style={{ cursor: 'pointer' }}
          />
          <Button
            icon={<MenuOutlined />}
            onClick={() => setDrawerVisible(true)}
          />
        </MobileHeader>
        <Drawer
          placement='right'
          onClose={() => setDrawerVisible(false)}
          open={drawerVisible}
          width={250}
        >
          {MenuComponent}
        </Drawer>
      </>
    )
  }

  return (
    <NavigationWrapper>
      <div className='logo'>
        <DesktopLogo
          src='https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/88af23246c5b80b6feb8a2888b2c7f6240a76e6d37e64dc070a3ae0f1e4778b3?apiKey=94b01dca26f547a09eaf89466406fc88&'
          alt='Company Logo'
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer' }}
        />
      </div>
      {MenuComponent}
    </NavigationWrapper>
  )
}
