/**
 * This code was generated by Builder.io
 */
import React from 'react'
import { Card, Typography, Row, Col } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const { Title, Paragraph } = Typography

interface AnnouncementCardProps {
  title: string
  content: string
  imageUrl: string
}

export const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
  title,
  content,
  imageUrl,
}) => {
  const ActionButtons = () => (
    <div className='action-buttons'>
      <EditOutlined
        className='edit-icon'
        style={{
          fontSize: '16px',
          color: '#8c8c8c',
          cursor: 'pointer'
        }}
      />
      <DeleteOutlined
        className='delete-icon'
        style={{
          fontSize: '16px',
          color: '#8c8c8c',
          cursor: 'pointer',
        }}
      />
    </div>
  )

  return (
    <Card bodyStyle={{ padding: '12px', display: 'flex' }}>
      <Row gutter={[24, 16]} style={{ flex: 1 }} className='announcement-row'>
        {/* Image Column */}
        <Col xs={24} sm={6}>
          <img
            src={imageUrl}
            alt=''
            className='announcement-image'
            role='presentation'
            style={{
              width: '100%',
              objectFit: 'cover',
              height: '200px',
              borderRadius: '8px',
            }}
          />
        </Col>

        {/* Content Column */}
        <Col xs={24} sm={16}>
          <div
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Title level={4}>{title}</Title>
            <Paragraph>{content}</Paragraph>
          </div>
        </Col>

        <ActionButtons />
      </Row>
    </Card>
  )
}
