/**
 * This code was generated by Builder.io
 */
import React from 'react'
import { Card, Typography, Row, Col } from 'antd'
import { AnnouncementCard } from '../comman/AnnouncementCard'

const { Title } = Typography

export const DashboardContent: React.FC = () => {
  return (
    <section className='dashboard-content' aria-label='Dashboard Content'>
      <Row justify='space-between'>
        <Col>
          <Title level={3}>Announcements</Title>
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <AnnouncementCard
            title='This is a sample announcement'
            content="Here's a short little announcement for this card. There's a heading above. The text is vertically aligned with the image. The image is 220 x 220 pixels with a rounded border radius."
            imageUrl='https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/26044f822e0635e00ebf31e86572bbe8c877229d3a7b98d07d3e7b67cfccbd55?apiKey=94b01dca26f547a09eaf89466406fc88&'
          />
        </Col>
        <Col span={24}>
          <AnnouncementCard
            title="Here's the second card"
            content="This is a short card with not much content. Now there's two lines."
            imageUrl='https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/1f25232c527e6449c1b6a8100f4e5aa209082010cc3d1d3424809eb31e845e72?apiKey=94b01dca26f547a09eaf89466406fc88&'
          />
        </Col>
        <Col span={24}>
          <AnnouncementCard
            title='The last card on the page'
            content='There should be three cards up in the announcement section. This is a long announcement that overflows the height of the image...'
            imageUrl='https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/1d803123e304f0c97e42e2d82737163ae76b0bab5a688f0cc482d45c8813abb5?apiKey=94b01dca26f547a09eaf89466406fc88&'
          />
        </Col>
      </Row>
    </section>
  )
}
