import * as React from "react";
import styled from "styled-components";
import { CloseOutlined } from "@ant-design/icons";

export const EditIncentiveHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <Title>Edit Incentive</Title>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h1`
  color: rgba(28, 48, 76, 1);
  font: 700 24px Mulish, sans-serif;
  margin: 0;
`;

const CloseIcon = styled(CloseOutlined)`
  font-size: 15px;
  cursor: pointer;
`;
