/**
 * This code was generated by Builder.io
 */
import React from 'react'
import { Card, Typography, Row, Col, Space } from 'antd'
import styles from './HeaderSection.module.css'
import Icon, { EditOutlined } from '@ant-design/icons'

const { Title, Text } = Typography

export const HeaderSection: React.FC = () => {
  return (
    <div className={styles.headerWrapper}>
      <section className={styles.headerSection}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage:
              'url("https://s3-alpha-sig.figma.com/img/d592/a59d/aab565811c520e1ff2a7c0b791b8b968?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Gqykdzc1W89sdZlP1dvtLQduAR-pLSePgIKFPri2NWXFUEdoOd5p9~3wjB5wyDC5cjBUWXRMCltYoT0AIVFXDcZQ13e7QGaB2K9RuASb7Ky0aNR2LwIcuNcIfO~wslU0WL2ECn19kR~HXm~Bn5hFCB~310tqDgzGMLe1Fp2eNQCOlKtLzcvo7H7JT28Wdk1vYW5RMVHDl0oKOZU4WuacjAAi6wL0fsx55KF5aqnhF7gdmqxpUBjdFABub1f6V2GIelaBrDu19-6R1LHo82OjE8oooGWj9pzuuq2FKfQLa0sSx2BrNCYAyy6aUV3WBAvtYtlRJVZfWUGqEadMbVYREQ__")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.8,
            pointerEvents: 'none',
          }}
        />
      </section>

      <Row
        gutter={[24, 24]}
        className={styles.cardsRow}
        style={{ margin: ' 0px 35px' }}
      >
        <Col xs={24} md={16}>
          <Card style={{ height: '100%' }}>
            <Title level={4}>Hi, John</Title>
            <Space align='start'>
              <Title level={1} style={{ marginTop: '0' }}>
                Welcome to BrightView Sales Incentives
              </Title>
            </Space>
            <Text>
              This is a sentence about the incentive program and why it's so
              amazing. Here's another sentence to give us two lines.
            </Text>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card style={{ height: '100%' }}>
            <div className='stats-card'>
              <div className='pending-incentives-box'>
                <img
                  src='https://s3-alpha-sig.figma.com/img/39c5/b656/9052a5dd4829783b67af9836d5bc42e3?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=KsDUPqwGV-LKJWBml2iadCysK9ZrlFl9eQiYlly9gCoBr16u3PTqQnk1NU7GP~QnoexSb7wyQV9DrqzP5nE27DbhvwA3Y-aGHKowxO6tdXjoMG2ogfixVH7YnQ43eMoXqr4a3IhT2QFOVVsj4Itqq6qP0ApjcmNlyQalECC9BrskzHYUgIkOLA0CRL29aonck8UcBdUrrx7RR-EtMw-LtdCneCBHanH9nkjVVWkA2tm1dAa2LNOAGTDXFhLpVAW8a4Jn2TmGbI7RDpBJSZhfDBVjPeC~SKS-x~wsk5koAHXtRRaOU8x2p1R-yel9Lv7Uy-Rjj3bWIqbLBKrGWZWnwg__'
                  alt='Dollar icon'
                  style={{ width: '80px', height: '48px' }}
                />
                <Title level={1}>$140</Title>
                <Text>Total Rewards Earned</Text>
              </div>
              <div className='stats-divider' />
              <Row gutter={[24, 16]}>
                <Col xs={12}>
                  <Title
                    level={2}
                    className='stat-number'
                    style={{ fontSize: 'calc(1.5rem + 1vw)' }}
                  >
                    47
                  </Title>
                  <Text>Earned This Month</Text>
                </Col>
                <Col xs={12}>
                  <Title
                    level={2}
                    className='stat-number'
                    style={{ fontSize: 'calc(1.5rem + 1vw)' }}
                  >
                    $832
                  </Title>
                  <Text>Last Month</Text>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
