import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Select, Row, Col } from 'antd'
import { IncentiveCard } from './IncentiveCard'
import { Pagination } from './Pagination'
import { CommonHeader } from '../../layout/components/CommanHeader'
import { EditIncentiveModal } from './EditIncentiveModal'

const FilterSection = styled.div`
  margin-bottom: 24px;
`

const ContentWrapper = styled.div`
  padding: 0 42px;

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`

export const IncentivesDashboard: React.FC = () => {
  const [isEditIncentiveModalOpen, setIsEditIncentiveModalOpen] =
    useState(false)

  const handleEditIncentiveModalClose = () => {
    setIsEditIncentiveModalOpen(false)
  }
  const handleEditIncentive = () => {
    setIsEditIncentiveModalOpen(true)
  }
  return (
    <main>
      <CommonHeader
        image='https://s3-alpha-sig.figma.com/img/8f90/687e/6a4e88186aff9c673b73718f331f55c0?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=k5vN4CiZl1EHRdIVetc84OdufzdDnB1b4QFqQGSVLJNIhRboFPVKr3X0Cz9JNRJ4kjTZ4xF9B7QavtoUkMHhJ2WyLDSltva42t6z9AgnPKN-nkSXmJRiUrJn4suyX8fbRj1MpqInwkvozPyCb1T2OB73~beyXyq97WO94tCgpUjvqov5z868PF8ZOhKmzs2ns3Lh5ZQOjwzujIN-4auRX1PVjhNdED~0sjUQDnFYtJ7YXPMEqKo6O3jViaDZIKSHGiIRYnznigtGzs0d-qX7MOaqTBOJqQ86pXG2zOfMkqyFApAYpZ08IXAwhvRtPG6nimQyxjGjwGIBFij3zoELMw__'
        title='Current Incentives'
        buttons={[
          <Button type='primary' size='large' onClick={handleEditIncentive}>
            + Add Incentive
          </Button>,
        ]}
      />
      <ContentWrapper>
        <FilterSection>
          <Select
            defaultValue='active'
            style={{ width: 317 }}
            options={[{ value: 'active', label: 'Active Incentives' }]}
          />
        </FilterSection>
        <Row gutter={[12, 12]}>
          {[
            {
              type: 'Qualification',
              amount: '$250,000.00 - $500,000.00',
              period: '10/24/2024 - 12/24/2024',
              reward: 50,
              iconSrc:
                'https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/9bf05f72bc5efe1f0ccd09bd74fd28fd15920fc136728346bd6b47e9a9117bdf?apiKey=94b01dca26f547a09eaf89466406fc88&',
            },
            {
              type: 'Id. Decision Makers',
              amount: '$250,000.00 - $500,000.00',
              period: '10/24/2024 - 12/24/2024',
              reward: 50,
              iconSrc:
                'https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/e6edc66fda34ca4f7cf731d232433feb0a452e33c74f90c344ac6c74e04cebbb?apiKey=94b01dca26f547a09eaf89466406fc88&',
            },
            {
              type: 'Proposal',
              amount: '$250,000.00 - $500,000.00',
              period: '10/24/2024 - 12/24/2024',
              reward: 100,
              iconSrc:
                'https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/7788b3f379b4a64d4d14c948b3e452580ece817c1a2f880533dc6a4b7dc21741?apiKey=94b01dca26f547a09eaf89466406fc88&',
            },
            {
              type: 'Negotiation',
              amount: '$250,000.00 - $500,000.00',
              period: '10/24/2024 - 12/24/2024',
              reward: 50,
              iconSrc:
                'https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/53cd0e540ec47b0b67621fc39eb09b84c37afbbf42eba2982a9691e9d55b6131?apiKey=94b01dca26f547a09eaf89466406fc88&',
            },
            {
              type: 'Closed Won',
              amount: '$250,000.00 - $500,000.00',
              period: '10/24/2024 - 12/24/2024',
              reward: 1000,
              iconSrc:
                'https://cdn.builder.io/api/v1/image/assets/94b01dca26f547a09eaf89466406fc88/c6e0b86273cc3bddcbbb2118b5642830d8e458da2236f9ab70e935fc9c24adf2?apiKey=94b01dca26f547a09eaf89466406fc88&',
            },
          ].map((card, index) => (
            <Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
              <IncentiveCard {...card} />
            </Col>
          ))}
        </Row>
        <Pagination />
        <EditIncentiveModal
          isOpen={isEditIncentiveModalOpen}
          onClose={handleEditIncentiveModalClose}
          incentiveId='123'
        />
      </ContentWrapper>
    </main>
  )
}
