/**
 * This code was generated by Builder.io
 */
import React from 'react'
import { Card, Typography, Row, Col, Space } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import styles from './HeaderSection.module.css'

const { Title, Text } = Typography

export const HeaderSection: React.FC = () => {
  return (
    <div className={styles.headerWrapper}>
      <section className={styles.headerSection}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage:
              'url("https://s3-alpha-sig.figma.com/img/d592/a59d/aab565811c520e1ff2a7c0b791b8b968?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Gqykdzc1W89sdZlP1dvtLQduAR-pLSePgIKFPri2NWXFUEdoOd5p9~3wjB5wyDC5cjBUWXRMCltYoT0AIVFXDcZQ13e7QGaB2K9RuASb7Ky0aNR2LwIcuNcIfO~wslU0WL2ECn19kR~HXm~Bn5hFCB~310tqDgzGMLe1Fp2eNQCOlKtLzcvo7H7JT28Wdk1vYW5RMVHDl0oKOZU4WuacjAAi6wL0fsx55KF5aqnhF7gdmqxpUBjdFABub1f6V2GIelaBrDu19-6R1LHo82OjE8oooGWj9pzuuq2FKfQLa0sSx2BrNCYAyy6aUV3WBAvtYtlRJVZfWUGqEadMbVYREQ__")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.8,
            pointerEvents: 'none',
          }}
        />
      </section>

      <Row
        gutter={[24, 24]}
        className={styles.cardsRow}
        style={{ margin: ' 0px 35px' }}
      >
        <Col xs={24} md={16}>
          <Card style={{ height: '100%' }}>
            <Text className='admin-badge'>Administrator</Text>
            <Title level={4} style={{ marginBottom: '0' }}>
              Hi, John
            </Title>
            <Space align='start'>
              <Title level={1} style={{ marginTop: '0' }}>
                Welcome to BrightView Sales Incentives
              </Title>
              <EditOutlined
                style={{ fontSize: '18px', color: '#666', marginTop: '8px' }}
              />
            </Space>
            <Space align='start'>
              <Text>
                This is a sentence about the incentive program and why it's so
                amazing. Here's another sentence to give us two lines.
              </Text>
              <EditOutlined
                style={{ fontSize: '16px', color: '#666', marginTop: '4px' }}
              />
            </Space>
          </Card>
        </Col>
        <Col xs={24} md={8}>
          <Card style={{ height: '100%' }}>
            <div className='stats-card'>
              <div className='pending-incentives-box'>
                <Title level={1}>14</Title>
                <Text>Pending Incentives</Text>
              </div>
              <Text className='review-link'>Review Pending Incentives</Text>
              <div className='stats-divider' />
              <Row gutter={[24, 16]}>
                <Col xs={12}>
                  <Title
                    level={2}
                    className='stat-number'
                    style={{ fontSize: 'calc(1.5rem + 1vw)' }}
                  >
                    47
                  </Title>
                  <Text>Incentives This Month</Text>
                </Col>
                <Col xs={12}>
                  <Title
                    level={2}
                    className='stat-number'
                    style={{ fontSize: 'calc(1.5rem + 1vw)' }}
                  >
                    $832
                  </Title>
                  <Text>Rewarded This Month</Text>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
