import React from 'react'
import styled from 'styled-components'
import { Card, Popconfirm, Space } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

interface IncentiveCardProps {
  type: string
  amount: string
  period: string
  reward: number
  iconSrc: string
}

const StyledCard = styled(Card)`
  border-radius: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  background: white;
  padding: 24px;
  position: relative;

  .ant-card-body {
    padding: 0;
  }

  @media (max-width: 768px) {
    padding: 16px;
  }
`

const CardLayout = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr auto;
  gap: 24px;
  align-items: start;

  @media (max-width: 768px) {
    grid-template-columns: 80px 1fr auto;
    gap: 16px;
  }
`

const IconWrapper = styled.div`
  background: #e8f0fe;
  border-radius: 8px;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  font-size: 28px;
  color: #333;
  margin: 0 0 2px 0;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const DetailGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const Label = styled.span`
  color: #999;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const Value = styled.span`
  color: #333;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const RewardBadge = styled.div`
  background: #e8f0fe;
  color: #0478c2;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`

const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  position: absolute;
  bottom: 24px;
  right: 24px;

  .anticon {
    font-size: 20px;
    color: #666;
    cursor: pointer;

    &:hover {
      color: #333;
    }
  }

  @media (max-width: 768px) {
    bottom: 16px;
    right: 16px;

    .anticon {
      font-size: 18px;
    }
  }
`

const Icon = styled.img`
  width: 100%;
  aspect-ratio: 0.92;
  object-fit: contain;
`

export const IncentiveCard: React.FC<IncentiveCardProps> = ({
  type,
  amount,
  period,
  reward,
  iconSrc,
}) => {
  return (
    <StyledCard>
      <CardLayout>
        <IconWrapper>
          <Icon src={iconSrc} alt={`${type} icon`} />
        </IconWrapper>

        <ContentSection>
          <Title>{type}</Title>
          <DetailGroup>
            <Label>Opportunity Amount</Label>
            <Value>${amount.toLocaleString()}</Value>
          </DetailGroup>
          <DetailGroup>
            <Label>Incentive Period</Label>
            <Value>{period}</Value>
          </DetailGroup>
        </ContentSection>

        <RightSection>
          <RewardBadge>Earn ${reward.toFixed(2)}</RewardBadge>
        </RightSection>
      </CardLayout>

      <ActionButtons>
        <EditOutlined />
        <DeleteOutlined />
      </ActionButtons>
    </StyledCard>
  )
}
