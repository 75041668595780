/**
 * This code was generated by Builder.io
 */
import React from 'react'
import IncentivesLayout from '../../components/incentives/IncentivesLayout'

const CurrentIncentive: React.FC = () => {
  return <IncentivesLayout />
}

export default CurrentIncentive
