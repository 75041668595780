import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Layout as AntLayout } from 'antd'
import { GlobalFooter } from './components/Footer'
import { Navigation } from './components/Navigation'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

const AppContainer = styled(AntLayout)`
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	@media (min-width: 768px) {
		flex-direction: row;
		height: 100vh;
		overflow: hidden;
	}
`

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;

	@media (min-width: 768px) {
		overflow-y: auto;
		height: 100vh;
		> *:first-child {
			flex: 1 0 auto;
		}
		> *:last-child {
			flex-shrink: 0;
		}
	}
`

const Layout = () => {
  return (
    <AppContainer>
      <Navigation isMobile={window.innerWidth < 768} />
      <ContentWrapper>
        <Suspense fallback={<>Loading...</>}>
          <Outlet />
        </Suspense>
        <GlobalFooter />
      </ContentWrapper>
    </AppContainer>
  )
}

export { Layout }
