import * as React from 'react'
import { Form, Upload, Input, DatePicker, Button, Select } from 'antd'
import styled from 'styled-components'
import { PlusOutlined } from '@ant-design/icons'

interface EditIncentiveFormProps {
  incentiveId: string
  onClose: () => void
}

export const EditIncentiveForm: React.FC<EditIncentiveFormProps> = ({
  incentiveId,
  onClose,
}) => {
  const [form] = Form.useForm()

  const handleSubmit = async (values: any) => {
    // Handle form submission
    onClose()
  }

  return (
    <FormContainer>
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        initialValues={{
          stage: 'Qualification',
          rewardAmount: '50.00',
        }}
      >
        <FormSection>
          <ImageSection>
            <Upload.Dragger
              name='image'
              multiple={false}
              showUploadList={false}
            >
              <UploadContent>
                <PlusOutlined />
                <div>Upload Image</div>
              </UploadContent>
            </Upload.Dragger>
            <ButtonGroup>
              <LibraryButton>Add Image From Library</LibraryButton>
              <UploadButton>Upload Image From Your Computer</UploadButton>
            </ButtonGroup>
          </ImageSection>
        </FormSection>

        <FormSection>
          <StageRewardGroup>
            <Form.Item label='Stage' name='stage' rules={[{ required: true }]}>
              <Select>
                <Select.Option value='Qualification'>Qualification</Select.Option>
                <Select.Option value='Discovery'>Discovery</Select.Option>
                <Select.Option value='Proposal'>Proposal</Select.Option>
                <Select.Option value='Negotiation'>Negotiation</Select.Option>
                <Select.Option value='Closing'>Closing</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label='Reward Amount'
              name='rewardAmount'
              rules={[{ required: true }]}
            >
              <Input prefix='$' />
            </Form.Item>
          </StageRewardGroup>
        </FormSection>

        <Divider />

        <FormSection>
          <SectionTitle>Opportunity Amount</SectionTitle>
          <AmountGroup>
            <Form.Item
              label='Minimum'
              name='minAmount'
              rules={[{ required: true }]}
            >
              <Input prefix='$' />
            </Form.Item>
            <Form.Item
              label='Maximum'
              name='maxAmount'
              rules={[{ required: true }]}
            >
              <Input prefix='$' />
            </Form.Item>
          </AmountGroup>
        </FormSection>

        <Divider />

        <FormSection>
          <SectionTitle>Incentive Period</SectionTitle>
          <DateGroup>
            <Form.Item
              label='Start Date'
              name='startDate'
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              label='End Date'
              name='endDate'
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </DateGroup>
        </FormSection>

        <SubmitButton type='primary' htmlType='submit'>
          Save Changes
        </SubmitButton>
      </Form>
    </FormContainer>
  )
}

const FormContainer = styled.div`
  padding: 0 39px;
`

const FormSection = styled.div`
  margin-bottom: 20px;
`

const ImageSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`

const UploadContent = styled.div`
  padding: 31px 63px;

  @media (max-width: 991px) {
    padding: 20px;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  flex: 1;
  justify-content: center;
`

const LibraryButton = styled(Button)`
  width: 100%;
  background-color: rgba(227, 227, 227, 1);
`

const UploadButton = styled(Button)`
  width: 100%;
  border: 1px solid rgba(204, 204, 204, 1);
`

const Divider = styled.div`
  height: 1px;
  background-color: rgba(204, 204, 204, 1);
  margin: 20px 0;
`

const SectionTitle = styled.h2`
  color: rgba(77, 77, 77, 1);
  font: 700 16px Mulish, sans-serif;
  margin-bottom: 23px;
`

const AmountGroup = styled.div`
  display: flex;
  gap: 26px;

  > div {
    flex: 1;
    width: 50%;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`

const DateGroup = styled.div`
  display: flex;
  gap: 26px;

  > div {
    flex: 1;
    width: 50%;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`

const SubmitButton = styled(Button)`
  display: block;
  margin: 58px auto 0;
  min-width: 130px;
  height: 40px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`

const StageRewardGroup = styled.div`
  display: flex;
  gap: 26px;

  > div {
    flex: 1;
    width: 50%;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`
